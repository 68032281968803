const currentLanguage = localStorage.getItem("i18nextLng") || "pt-BR";

export function capitalizeFirst(phrase) {
  const array = phrase.toLowerCase().split("");
  array[0] = array[0].toUpperCase();
  return array.join("");
}

export function removeExcessiveSpaces(inputString) {
  const trimmedString = inputString.trim();
  return trimmedString.replace(/\s+/g, " ");
}

export function getMonthName(monthNumber) {
  const date = new Date();
  date.setDate(1);
  date.setMonth(monthNumber - 1);
  const monthName = date.toLocaleString(currentLanguage, { month: "long" });
  return capitalizeFirst(monthName);
}

export function getMonthAbbreviation(monthNumber) {
  const date = new Date();
  date.setDate(1);
  date.setMonth(monthNumber - 1);
  let monthName = date.toLocaleString(currentLanguage, { month: "short" });
  if (monthName[monthName.length - 1] === ".") monthName = monthName.slice(0, -1);
  return capitalizeFirst(monthName);
}

export function toNDecimalsString(number, n) {
  return Number(number).toLocaleString(currentLanguage, {
    style: "decimal",
    maximumFractionDigits: n,
  });
}

export function to2DecimalsNumber(number) {
  return Math.round(Number(number) * 100) / 100;
}

export function defineMonthColumns(predictions) {
  const uniqueMonths = predictions.filter((prediction, index, arr) => {
    return index === arr.findIndex(ind => areMonthObjsEqual(ind, prediction));
  });
  return sortByMonthAndYear(uniqueMonths);
}

function areMonthObjsEqual(obj1, obj2) {
  return obj1.month === obj2.month && obj1.year === obj2.year && obj1.wape === obj2.wape;
}

export function sortByMonthAndYear(arr) {
  return arr.sort((a, b) => a.year - b.year || (a.week || a.month) - (b.week || b.month));
}

export function getProductPrediction(month, productId, predictionData) {
  const prediction = predictionData.find(
    prediction => prediction.year === month.year && prediction.month === month.month && prediction.productId === productId
  );
  return prediction !== -1 ? prediction : {};
}

export function getAllPredictionsFromMonth(month, predictionData) {
  const prediction = predictionData.filter(prediction => prediction.year === month.year && prediction.month === month.month);
  return prediction !== -1 ? prediction : {};
}

export function isFutureMonth(forecastMonthAndYear, predictionMonthAndYear) {
  if (predictionMonthAndYear.year > forecastMonthAndYear.year) return true;
  if (predictionMonthAndYear.year < forecastMonthAndYear.year) return false;
  if (predictionMonthAndYear.year === forecastMonthAndYear.year)
    return predictionMonthAndYear.month >= forecastMonthAndYear.month ? true : false;
}

export function areObjectsEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

export function getSeasonName(allSeasons, seasonId) {
  const foundSeason = allSeasons.find(season => season.id === seasonId);
  return foundSeason.name;
}

export function getLatestForecastFromMonth(month, allForecasts) {
  const sameMonthForecasts = allForecasts.filter(forecast => forecast.month === month.month && forecast.year === month.year);
  return sameMonthForecasts.reduce((curr, acc) => {
    if (acc.day > curr.day) return acc;
    else return curr;
  });
}

export function getForecastPredictions(forecastId, allPredictions) {
  return allPredictions.filter(prediction => prediction.forecast.forecastId === forecastId);
}

export function getPreviousMonth(month) {
  const currentDate = new Date(month.year, month.month - 1, 1);
  currentDate.setMonth(currentDate.getMonth() - 1);

  return {
    month: currentDate.getMonth() + 1,
    year: currentDate.getFullYear(),
  };
}

export function defineWeekColumns(predictions) {
  const uniqueWeeks = predictions.filter((prediction, index, arr) => {
    return index === arr.findIndex(ind => areWeekObjsEqual(ind, prediction));
  });
  return uniqueWeeks;
}

function areWeekObjsEqual(obj1, obj2) {
  return obj1.week === obj2.week && obj1.year === obj2.year;
}

export function sortByWeekAndYear(arr) {
  return arr.sort((a, b) => {
    const aDate = new Date(a.year, a.week - 1, 1);
    const bDate = new Date(b.year, b.week - 1, 1);
    return aDate - bDate;
  });
}

function getWeekNumber(d) {
  d = new Date(+d);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  var yearStart = new Date(d.getFullYear(), 0, 1);
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  return [d.getFullYear(), weekNo];
}

export function weeksInYear(year) {
  var d = new Date(year, 11, 31);
  var week = getWeekNumber(d)[1];
  return week === 1 ? 52 : week;
}

export function sumColumn(predictions, columnName) {
  return predictions.reduce((total, currentObject) => {
    if (currentObject[columnName]) return Number(total) + Number(currentObject[columnName]);
    else return total;
  }, 0);
}

export function defineGroupFilterOptions(products) {
  const level0ProductIds = products.map(product => product.productId);
  const filterOptions = [{ displayName: "Empresa", value: 1, level: "level0", productIds: level0ProductIds }];

  const allLevel1Options = products.map(product => product.level1);
  const level1Options = [...new Set(allLevel1Options)];
  level1Options.forEach((option, index) => {
    const levelProductIds = products.filter(product => product.level1 === option).map(product => product.productId);
    filterOptions.push({
      displayName: capitalizeFirst(option),
      value: index + 2,
      level: "level1",
      productIds: levelProductIds,
    });
  });

  return filterOptions;
}

export function getNextLevel(selected, nextLevel, products) {
  const filteredProducts = getProducts(selected, products);
  const allNextLevelOptions = filteredProducts.map(product => product[nextLevel]);
  const nextLevelOptions = [...new Set(allNextLevelOptions)];

  return nextLevelOptions.map(option => {
    const optionProducts = filteredProducts.filter(product => product[nextLevel] === option);
    const productIds = optionProducts.map(product => product.productId);
    return { name: option, productIds: productIds };
  });
}

function getProducts(selected, products) {
  if (selected.level === "level0") {
    return products;
  } else {
    const filteredProducts = products.filter(product => product[selected.level] === selected.displayName.toUpperCase());
    return filteredProducts;
  }
}

export function formatAdjustPlanIndex(number, unit) {
  const prefix = unit === "currency" ? "R$ " : "";
  return `${number < 0 ? "-" : ""} ${prefix} ${Math.ceil(Math.abs(number)).toLocaleString("pt-BR")}`;
}
