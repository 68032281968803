import React, { useContext, useState } from "react";
import { Icon, Text } from "@aidron/aidron-ds";
import { getProductPrediction } from "../../../../../../utils/utils";
import { CompanyContext } from "../../../../../../templates/context/CompanyContext";

import * as S from "./NewPlanCell.style";

import Cell from "../Cell/Cell";

export default function NewPlanCell({
  showInputs,
  column,
  inputs,
  setInputs,
  saveLoading,
  month,
  productId,
  predictions,
  setShowInputs,
}) {
  const { userInfo } = useContext(CompanyContext);
  const unit = userInfo.company.unit;

  const inputObjFromInputs = getProductPrediction(month, productId, inputs);
  const inputObjFromPredictions = getProductPrediction(month, productId, predictions);
  const id = inputObjFromPredictions?.id;

  const initialLocalInput = inputObjFromInputs?.editedNewPlan
    ? inputObjFromInputs.editedNewPlan
    : inputObjFromPredictions.editedNewPlan
    ? formatInput(inputObjFromPredictions.editedNewPlan)
    : formatInput(inputObjFromPredictions.newPlan);

  const [localInput, setLocalInput] = useState(initialLocalInput);

  function formatInput(number) {
    return Math.ceil(number);
  }

  function onInputChange(value) {
    const newInputs = [...inputs];
    const index = inputs.findIndex(input => input.id === id);

    if (index !== -1) {
      newInputs[index] = { ...newInputs[index], editedNewPlan: value };
    } else {
      newInputs.push({ id, editedNewPlan: value });
    }
    setLocalInput(value);
    setInputs(newInputs);
  }

  function returnToOriginalValue() {
    const newInputs = [...inputs];
    const index = inputs.findIndex(input => input.id === id);

    if (index !== -1) {
      newInputs[index] = { ...newInputs[index], editedNewPlan: null };
    } else {
      newInputs.push({ id, editedNewPlan: null });
    }
    setInputs(newInputs);
  }

  function defineNewPlanValue(obj) {
    return obj.editedNewPlan ? obj.editedNewPlan : obj.newPlan;
  }

  return (
    <S.OuterContainer>
      <S.InputContainer>
        {showInputs && column.name === "newPlan" ? (
          <S.Input
            type="number"
            value={localInput}
            onChange={e => onInputChange(e.target.value)}
            disabled={saveLoading}
            width={inputObjFromPredictions.editedNewPlan ? "60px" : "80px"}
          />
        ) : (
          <>
            <Cell
              prediction={
                column.name === "newPlan" ? defineNewPlanValue(inputObjFromPredictions) : inputObjFromPredictions?.[column.name]
              }
              format={unit}
              color={column.color}
              adjustmentColumn={column.name === "opportunityAdjustment"}
            />
          </>
        )}
        {inputObjFromPredictions.editedNewPlan && column.name === "newPlan" && (
          <S.IconButton
            onClick={() => {
              setShowInputs(true);
              setLocalInput(formatInput(inputObjFromPredictions.newPlan));
              returnToOriginalValue();
            }}
          >
            <Icon icon="rotate-ccw" color="color-primary-medium" size={15} stroke={2.5} />
            <S.TooltipBox>
              <S.Tooltip className="custom-tooltip">
                <Text>Retornar ao valor original</Text>
              </S.Tooltip>
            </S.TooltipBox>
          </S.IconButton>
        )}
      </S.InputContainer>
    </S.OuterContainer>
  );
}
