import styled from "styled-components";

const TableRow = styled.tr`
  --bs-table-accent-bg: white !important;
  &:hover {
    --bs-table-accent-bg: unset !important;
  }
`;

const Container = styled.div`
  width: 85vw;
  position: sticky;
  left: 9px;
  min-height: 358px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ChartsRow = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.6fr;
  margin-top: 5px;
`;

const Column = styled.div`
  display: flex;
  justify-content: center;
`;

const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
`;

const SmallCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

const ProductDriversColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ProductDriversChart = styled.div`
  width: min-content;
  height: min-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const InnerChartContainer = styled.div`
  width: 450px;
`;

const Indexes = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const IndexContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`;

const Index = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  p {
    line-height: 1.3;
  }
`;

export {
  Container,
  Header,
  Column,
  FirstColumn,
  SmallCardsContainer,
  TableRow,
  ProductDriversColumn,
  ProductDriversChart,
  InnerChartContainer,
  ChartsRow,
  Indexes,
  IndexContainer,
  Index,
};
