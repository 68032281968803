import React, { useContext } from "react";
import { Icon } from "@aidron/aidron-ds";
import { formatAdjustPlanIndex } from "../../../../../../../../utils/utils";
import { CompanyContext } from "../../../../../../../../templates/context/CompanyContext";

import * as S from "./SumCard.style";

import CustomCard from "../../../../../../../../components/StyledComponents/CustomCard/CustomCard.style";
import CustomHeading from "../../../../../../../../components/StyledComponents/CustomHeading/CustomHeading.style";
import CustomText from "../../../../../../../../components/StyledComponents/CustomText/CustomText.style";

export default function SumCard({ icon, number, numberColor, label, prefix }) {
  const { userInfo } = useContext(CompanyContext);
  const unit = userInfo.company.unit;

  return (
    <CustomCard backgroundColor="lightGrey" padding="3px 10px">
      <S.OuterContainer>
        <S.IconContainer>
          <Icon icon={icon} size={30} color="color-neutral-40" />
        </S.IconContainer>
        <S.InnerContainer>
          <CustomHeading size="xs" color={numberColor ? numberColor : ""} margin="0">
            {`${prefix ? prefix : ""} ${formatAdjustPlanIndex(Math.abs(number), unit)}`}
          </CustomHeading>
          <CustomText weight="bold">{label}</CustomText>
        </S.InnerContainer>
      </S.OuterContainer>
    </CustomCard>
  );
}
