import React from "react";
import { Text } from "@aidron/aidron-ds";

import { toNDecimalsString } from "../../utils/utils";

import * as S from "./ClientFlowSection.style";

import CustomText from "../StyledComponents/CustomText/CustomText.style";
import IconButtonWithModal from "../IconButtonWithModal/IconButtonWithModal";
import CustomHeading from "../StyledComponents/CustomHeading/CustomHeading.style";

export default function ClientFlowSection({ clientFlow, numberSize }) {
  return clientFlow ? (
    <S.OuterContainer>
      <S.InnerContainer>
        <S.SubtitleContainer>
          <CustomText weight="bold" size="15px">
            Nível de dependência de Fluxo para Venda
          </CustomText>
          <IconButtonWithModal
            icon="help-circle"
            modalHeading="Ajuda: nível de dependência de Fluxo para Venda"
            renderModalContent={() => (
              <Text>
                Caso o % seja muito alto, os fatores listados acima terão pouco efeito se o fluxo de clientes não aumentar nos
                locais onde este produto possui uma venda alta. Para produtos com dependência de Fluxo acima de 60%, recomendamos
                ações na operação que tragam mais visibilidade ao produto.
              </Text>
            )}
          />
        </S.SubtitleContainer>
        <CustomHeading size={numberSize ? numberSize : "lg"} color="orange">
          {toNDecimalsString(clientFlow, 0)}%
        </CustomHeading>
      </S.InnerContainer>
    </S.OuterContainer>
  ) : (
    <></>
  );
}
