import React, { useContext } from "react";
import { CompanyContext } from "../../../../../../../templates/context/CompanyContext";
import { formatAdjustPlanIndex } from "../../../../../../../utils/utils";

import * as S from "./SumCards.style";

import SumCard from "./SumCard/SumCard";
import CustomCard from "../../../../../../../components/StyledComponents/CustomCard/CustomCard.style";
import CustomText from "../../../../../../../components/StyledComponents/CustomText/CustomText.style";
import CustomHeading from "../../../../../../../components/StyledComponents/CustomHeading/CustomHeading.style";

export default function SumCards({ predictions, productId }) {
  const { userInfo } = useContext(CompanyContext);
  const unit = userInfo.company.unit;

  const filteredPredictions = predictions.filter(prediction => prediction.productId === productId);

  const clientPlanSum = filteredPredictions.reduce((acc, curr) => {
    if (curr.clientPrediction) return acc + Number(curr.clientPrediction);
    else return acc;
  }, 0);

  const newPlanSum = filteredPredictions.reduce((acc, curr) => {
    const property = curr.editedNewPlan ? "editedNewPlan" : "newPlan";
    if (curr[property]) return acc + Number(curr[property]);
    else return acc;
  }, 0);

  const difference = clientPlanSum - newPlanSum;

  const overstock = filteredPredictions.reduce((acc, curr) => {
    if (curr.opportunityAdjustment < 0) return acc + Number(curr.opportunityAdjustment);
    else return acc;
  }, 0);

  const increaseInSales = filteredPredictions.reduce((acc, curr) => {
    if (curr.opportunityAdjustment > 0) return acc + Number(curr.opportunityAdjustment);
    else return acc;
  }, 0);

  return (
    <>
      <S.Container>
        <SumCard icon="file-text" number={clientPlanSum} numberColor="mediumGrey" label="Plano Atual" />
        <SumCard icon="target" number={newPlanSum} numberColor="orange" label="Novo Plano" />
        <SumCard
          icon="dollar-sign"
          number={difference}
          numberColor="mediumGrey"
          label="Diferença do Plano"
          prefix={difference > 0 ? "-" : "+"}
        />
      </S.Container>

      <S.Container>
        <CustomCard padding="5px 10px" margin="15px 0 0 0">
          <S.IndexContainer>
            <S.Index>
              <CustomHeading weight="bold" textAlign="center" size="xs" color="orange">
                {formatAdjustPlanIndex(overstock, unit)}
              </CustomHeading>
              <div>
                <CustomText weight="bold" textAlign="center" whiteSpace="break-spaces">
                  Excesso de Estoque Evitado
                </CustomText>
              </div>
            </S.Index>
          </S.IndexContainer>
        </CustomCard>

        <CustomCard padding="5px 10px">
          <S.IndexContainer>
            <S.Index>
              <CustomHeading weight="bold" textAlign="center" size="xs" color="orange">
                {formatAdjustPlanIndex(increaseInSales, unit)}
              </CustomHeading>
              <div>
                <CustomText weight="bold" textAlign="center" whiteSpace="break-spaces">
                  Aumento de Vendas
                </CustomText>
              </div>
            </S.Index>
          </S.IndexContainer>
        </CustomCard>
      </S.Container>
    </>
  );
}
