import React, { useContext, useState, useRef, useEffect } from "react";
import { Button } from "@aidron/aidron-ds";
import { adjustPlanColumns } from "../../../../../../utils/tableColumns";
import { ForecastContext } from "../../../../../../templates/context/ForecastContext";

import * as S from "./AdjustPlanProductRow.style";

import HierarchyBodyCells from "../../../../../../components/HierarchyBodyCells/HierarchyBodyCells";
import NewPlanCell from "../NewPlanCell/NewPlanCell";
import ProductAccordion from "../ProductAccordion/ProductAccordion";

export default function AdjustPlanProductRow({
  freezeColumns,
  productId,
  sumPreviousColumnsWidth,
  setHierarchyColumnsWidth,
  showSeasonColumn,
  evaluateLastCategoryColumn,
  defineHierarchyToDisplay,
  selectedHierarchyIndexes,
  selectedProductIds,
  monthColumns,
  showInputs,
  inputs,
  setInputs,
  saveLoading,
  setShowInputs,
  predictions,
  defineLeftCellBorder,
  setFirstColumnWidth,
}) {
  const { selectedForecast } = useContext(ForecastContext);
  const [openAccordion, setOpenAccordion] = useState(false);

  const firstColumn = useRef(null);

  const hierarchyColumnsCount = defineHierarchyToDisplay().length;
  const monthColumnsCount = monthColumns.length * adjustPlanColumns.length;
  const columnsCount = hierarchyColumnsCount + monthColumnsCount;

  function getAnyPrediction(productId) {
    return predictions.find(prediction => prediction.productId === productId);
  }

  useEffect(() => {
    setFirstColumnWidth(firstColumn.current.getBoundingClientRect().width);
  }, []);

  return (
    <>
      <tr>
        <S.ButtonCell ref={firstColumn} freezeColumns={freezeColumns}>
          <Button
            icon={openAccordion ? "chevrons-up" : "chevrons-down"}
            type={openAccordion ? "filled" : "outlined"}
            onClick={() => setOpenAccordion(!openAccordion)}
          />
        </S.ButtonCell>
        <HierarchyBodyCells
          freezeColumns={freezeColumns}
          productId={productId}
          sumPreviousColumnsWidth={sumPreviousColumnsWidth}
          setHierarchyColumnsWidth={setHierarchyColumnsWidth}
          showSeasonColumn={showSeasonColumn}
          evaluateLastCategoryColumn={evaluateLastCategoryColumn}
          defineHierarchyToDisplay={defineHierarchyToDisplay}
          selectedHierarchyIndexes={selectedHierarchyIndexes}
          selectedProductIds={selectedProductIds}
        />
        {monthColumns.map((month, index) =>
          adjustPlanColumns.map((column, i) => (
            <td
              className={`text-center background-color-white ${defineLeftCellBorder(index, i) ? "cell-left-border" : ""}`}
              key={i}
            >
              <NewPlanCell
                showInputs={showInputs}
                column={column}
                inputs={inputs}
                setInputs={setInputs}
                saveLoading={saveLoading}
                month={month}
                productId={productId}
                predictions={predictions}
                setShowInputs={setShowInputs}
              />
            </td>
          ))
        )}
      </tr>
      {openAccordion && (
        <ProductAccordion
          forecastId={selectedForecast.forecastId}
          productId={productId}
          prediction={getAnyPrediction(productId)}
          columnsCount={columnsCount}
          predictions={predictions}
        />
      )}
    </>
  );
}
