import React, { useContext } from "react";
import { CompanyContext } from "../../../../../templates/context/CompanyContext";
import { formatAdjustPlanIndex } from "../../../../../utils/utils";

import * as S from "./HierarchySumBox.style";

import CustomCard from "../../../../../components/StyledComponents/CustomCard/CustomCard.style";
import CustomText from "../../../../../components/StyledComponents/CustomText/CustomText.style";

export default function HierarchySumBox({ group, boxCount }) {
  const { userInfo } = useContext(CompanyContext);
  const unit = userInfo.company.unit;
  return (
    <CustomCard padding="10px" width={boxCount < 3 ? "300px" : "225px"} margin="0px 15px">
      <S.OuterContainer>
        <CustomText allCaps color="mediumGrey" weight="bold" textAlign="center" size="16px" margin="0">
          {group.name}
        </CustomText>

        <S.SumLine>
          <CustomText color="mediumGrey" weight="bold">
            ATUAL
          </CustomText>
          <CustomText color="mediumGrey" weight="bold" size="16px">
            {formatAdjustPlanIndex(group.clientPlanSum, unit)}
          </CustomText>
        </S.SumLine>
        <S.SumLine>
          <CustomText color="mediumGrey" weight="bold">
            NOVO
          </CustomText>
          <CustomText color="orange" weight="bold" size="16px">
            {formatAdjustPlanIndex(group.newPlanSum, unit)}
          </CustomText>
        </S.SumLine>
        <S.Line />
        <S.SumLine>
          <CustomText color="mediumGrey" weight="bold">
            DIFERENÇA
          </CustomText>
          <CustomText color={group.difference > 0 ? "red" : "blue"} weight="bold" size="16px">
            {`${group.difference > 0 ? "-" : "+"} ${formatAdjustPlanIndex(Math.abs(group.difference))}`}
          </CustomText>
        </S.SumLine>
      </S.OuterContainer>
    </CustomCard>
  );
}
