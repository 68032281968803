import styled from "styled-components";

const colors = [
  { name: "lightGrey", code: "#f5f5f7" },
  { name: "mediumLightGrey", code: "#fafafa" },
  { name: "lighterGrey", code: "#fcfcfc" },
  { name: "transparentDarkOrange", code: "#fad6bf" },
];

function getColor(name) {
  const code = colors.find(color => color.name === name)?.code;
  return code ? code : "";
}

const CustomCard = styled.div`
  position: ${({ position }) => (position ? position : "relative")};
  left: ${({ left }) => (left ? left : "")};
  display: ${({ display }) => (display ? display : "flex")};
  flex-direction: column;
  word-wrap: break-word;
  background-clip: border-box;
  border: ${({ border }) => (border ? border : "0.0625rem solid rgba(17, 24, 39, 0.125)")};
  box-shadow: ${({ boxShadow }) => (boxShadow ? boxShadow : "0 4px 8px 0 rgba(0, 0, 0, 0.04)")};
  border-radius: 4px;
  padding: ${({ padding }) => (padding ? padding : "16px")};
  background-color: ${({ backgroundColor }) => (backgroundColor ? `${getColor(backgroundColor)} !important` : "#fff")};
  margin: ${({ margin }) => (margin ? `${margin} !important` : "")};
  cursor: ${({ cursor }) => (cursor ? cursor : "")};
  width: ${({ width }) => (width ? width : "")};
  height: ${({ height }) => (height ? height : "")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : 0)};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "")};

  &:hover {
    background-color: ${({ greyBackgroundOnHover }) => (greyBackgroundOnHover ? "rgba(17, 24, 39, 0.07)" : "")};
  }
`;

export default CustomCard;
