import React from "react";
import { Text, Icon } from "@aidron/aidron-ds";

import * as S from "./AdjustPlanDriversChart.style";

import ContentLoader from "../../../../../../../components/ContentLoader/ContentLoader";
import ClientFlowSection from "../../../../../../../components/ClientFlowSection/ClientFlowSection";
import CustomText from "../../../../../../../components/StyledComponents/CustomText/CustomText.style";
import AdjustDriversChartSeries from "./AdjustDriversChartSeries/AdjustDriversChartSeries";

export default function AdjustPlanDriversChart({ orderedProductDrivers, clientFlow }) {
  return (
    <ContentLoader
      resultCondition={orderedProductDrivers.length}
      contentCondition={orderedProductDrivers?.length}
      renderError={() => <Text>Esta funcionalidade estará disponivel em breve.</Text>}
    >
      <S.TitleContainer>
        <CustomText size="15px !important" weight="bold">
          Fatores que impactam a venda deste produto
        </CustomText>
        <S.Button>
          <Icon icon="help-circle" size={17} />
          <S.TooltipBox>
            <S.Tooltip className="custom-tooltip">
              <Text size="xs">
                Esta análise identifica % de impacto de cada fator na venda deste produto. Ela é importante para dignóstico e
                proposição de ações que possam otimizar estoque e/ou aumentar as vendas.
              </Text>
            </S.Tooltip>
          </S.TooltipBox>
        </S.Button>
      </S.TitleContainer>

      <S.OuterChartContainer>
        <S.InnerChartContainer>
          {orderedProductDrivers.map(driver => (
            <AdjustDriversChartSeries series={driver} key={driver.driver} />
          ))}
        </S.InnerChartContainer>
      </S.OuterChartContainer>

      <ClientFlowSection clientFlow={clientFlow} numberSize="sm" />
    </ContentLoader>
  );
}
