import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const InnerCard = styled.div`
  display: flex;
`;

const IndexContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`;

const Index = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    line-height: 1.3;
  }
`;

export { Container, InnerCard, IndexContainer, Index };
